<div
	class="list-group list-group-flush"
	*ngIf="showMenu"
	(click)="onMenuClick()"
>
	<a
		class="list-group-item list-group-item-action list-group-item-warning"
		routerLink="/fastbuy/regions"
	>
		<fa-icon [icon]="'book'" class="mr-2"></fa-icon>
		<span class="bl-header-menu-name" i18n="@@headerMenuOrderItems"
			>Order items</span
		>
	</a>
	<a
		class="list-group-item list-group-item-action"
		routerLink="/u/items"
		*ngIf="isLoggedIn()"
	>
		<fa-icon [icon]="'book-open'" class="mr-2"></fa-icon>
		<span class="bl-header-menu-name" i18n="@@headerMenuYourItems"
			>Your items</span
		>
	</a>
	<a
		class="list-group-item list-group-item-action"
		routerLink="/u/order"
		*ngIf="isLoggedIn()"
	>
		<fa-icon [icon]="'receipt'" class="mr-2"></fa-icon>
		<span class="bl-header-menu-name" i18n="@@headerMenuOrderHistory"
			>Order history</span
		>
	</a>

	<a class="list-group-item list-group-item-action" routerLink="info/branch">
		<span class="bl-header-menu-name" i18n="@@headerMenuBranchInfo"
			>Branch info</span
		>
	</a>
	<a
		class="list-group-item list-group-item-action"
		routerLink="/info/general"
	>
		<span class="bl-header-menu-name" i18n="@@headerMenuGeneralInfo"
			>General info</span
		>
	</a>

	<a
		class="list-group-item list-group-item-action"
		routerLink="/info/contact"
	>
		<span class="bl-header-menu-name" i18n="@@headerMenuContact"
			>Contact info</span
		>
	</a>

	<a
		class="list-group-item list-group-item-action list-group-item-secondary"
		routerLink="/u/edit"
		*ngIf="isLoggedIn()"
	>
		<fa-icon [icon]="'user-cog'" class="mr-2"></fa-icon>
		<span class="bl-header-menu-name" i18n="@@headerMenuUserSettings"
			>User settings</span
		>
	</a>
	<a
		class="list-group-item list-group-item-action list-group-item-danger"
		routerLink="/auth/logout"
		*ngIf="isLoggedIn()"
	>
		<fa-icon [icon]="'sign-out-alt'" class="mr-2"></fa-icon>
		<span class="bl-header-menu-name" i18n="@@headerMenuLogout"
			>Logout</span
		>
	</a>

	<a
		class="list-group-item list-group-item-action list-group-item-primary"
		routerLink="/auth/register"
		*ngIf="!isLoggedIn()"
	>
		<span class="bl-header-menu-name" i18n="@@headerMenuRegister"
			>Register</span
		>
	</a>
	<a
		class="list-group-item list-group-item-action list-group-item-success"
		routerLink="auth/login"
		*ngIf="!isLoggedIn()"
	>
		<span class="bl-header-menu-name" i18n="@@headerMenuLogin">Login</span>
	</a>
</div>
